import * as React from 'react';
import { getId, IDropdownOption } from 'office-ui-fabric-react';
import * as Metadata from "../../entities/Metadata";
import TextInput from './inputs/TextInput';
import DropdownInput from "./inputs/DropdownInput";
import DatePickerInput from "./inputs/DatePickerInput";
import { SliderProps } from "./inputs/SliderInput";
import PersonPickerInput from "./inputs/PersonPickerInput";
import ErrorInput from "./inputs/ErrorInput";
import { IPortfolioInfo, IProgramInfo, IProjectInfo } from "../../store/ProjectsListStore";
import OptionsPicker, { Option, OptionsPickerProps } from "./inputs/OptionsPicker";
import FlagInput from "./inputs/FlagInput";
import { Validator, ValidationBuilder, IValidator } from '../../validation';
import { EntityInfo } from './inputs/EntityPicker';
import EntityRefInput from './inputs/EntityRefInput';
import { formatFieldValue, notUndefined } from "../utils/common";
import DebouncedSlider from "./DebouncedSlider";
import ResourceFormatter from './formatters/ResourceFormatter';
import { IUserInfo, Dictionary, EntityType, IRefInfo, IProcessStageInfo } from "../../entities/common";
import NumberFilter from './filters/NumberFilter';
import { DateFilter } from './filters/DateFilter';
import SliderFilter from './filters/SliderFilter';
import FlagFilter from './filters/FlagFilter';
import { IFieldMapping, IFieldMappingKeyMap, MappingType, IFieldMappingDto, ExternalFieldInfo, MapConfig } from "../../store/integration/common";
import { IIdeaInfo } from '../../store/IdeasListStore';
import { IChallengeInfo } from '../../store/ChallengesListStore';
import TagPicker from './inputs/TagPicker';
import { post } from '../../fetch-interceptor';
import { IObjectiveInfo } from '../../store/ObjectivesListStore';
import { UserState } from '../../store/User';
import { RefFormatter } from './formatters/RefFormatter';
import PortfolioFormatter from './formatters/PortfolioFormatter';
import ProgramFormatter from './formatters/ProgramFormatter';
import ProjectFormatter from './formatters/ProjectFormatter';
import IdeaFormatter from './formatters/IdeaFormatter';
import { FlagEyeIconSet, FlagFormatter } from './formatters/FlagFormatter';
import { DateFormatter, DateTimeFormatter } from './formatters/DateFormatter';
import { TextFormatter } from './formatters/TextFormatter';
import { GroupFormatter, GroupFormatterWithoutUngrouped } from './formatters/GroupFormatter';
import { DropdownFormatter, ToggleOptionFormatter } from './formatters/DropdownFormatter';
import { TagFormatter } from './formatters/TagFormatter';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import HyperlinkInput, { Hyperlink } from './inputs/HyperlinkInput';
import { HyperlinkFormatter } from './formatters/HyperlinkFormatter';
import TextDropdownFormatter from './formatters/TextDropdownFormatter';
import { CardDropdownFormatter } from './formatters/TextDropdownFormatter/TextDropdownFormatter';
import { IInputProps } from './interfaces/IInputProps';
import { TenantState } from '../../store/Tenant';
import { searchPathMap } from '../../entities/Metadata';
import EntityFieldInputElement from './inputs/EntityFieldInputElement';
import { FieldsService } from './FieldsService';
import TextFieldInputElement from './inputs/TextFieldInputElement';
import IntegerFieldInputElement from './inputs/IntegerFieldInputElement';
import ProgramFieldInputElement from './inputs/ProgramFieldInputElement';
import PortfolioFieldInputElement from './inputs/PortfolioFieldInputElement';
import IdeaFieldInputElement from './inputs/IdeaFieldInputElement';
import ProjectFieldInputElement from './inputs/ProjectFieldInputElement';
import { FlagEditor, FlagEyeEditor } from './inputs/FlagEditor';
import { ImpactView } from '../views/list/columns/Impact';
import { PriorityView } from '../views/list/columns/task/Priority';
import { nameof } from '../../store/services/metadataService';
import { IKeyDateAttrs, ILayoutInfo } from '../../entities/Subentities';
import CategoryColorStageView from '../views/list/columns/CategoryColorStageView';
import ColorDropdownOption from './inputs/ColorDropdownOption';
import { StagePhaseInfo } from '../../store/process/common';
import { getStageFieldLabel } from '../views/list/columns/ColorStageView';

// to give React possibility to cache the component (blinking on ui appears without it)
const ResourceWithNavigation = (_: { value?: IUserInfo | IUserInfo[] }) => <ResourceFormatter resource={_.value} withNavigation={true} />;
const PersonWithoutNavigation = (_: { value?: IUserInfo | IUserInfo[] }) => <ResourceFormatter resource={_.value} withNavigation={false} />;

export class DisplayFieldService {
    public static getCardViewFieldFormatter(field: Metadata.Field, withNavigation: boolean, entityType?: EntityType)
        : React.FunctionComponent<any> | undefined {
        const settings = field.settings;
        if (field.type === Metadata.FieldType.Text && FieldsService.isDropDown(field)) {
            return (props) => <CardDropdownFormatter {...props} options={settings?.options || []} />;
        }

        return this.getFieldFormatter(field, withNavigation, entityType);
    }

    public static getFieldFormatter(field: Metadata.Field, withNavigation: boolean, entityType?: EntityType): React.FunctionComponent<any> | undefined {
        const settings = field.settings;
        switch (field.type) {
            case Metadata.FieldType.Group:
                return field.settings?.hideUngroupped
                    ? GroupFormatterWithoutUngrouped
                    : GroupFormatter;
            case Metadata.FieldType.Flag:
                if (entityType === EntityType.KeyDate && field.name === nameof<IKeyDateAttrs>("ShowOnTimeline")) {
                    return _ => <FlagFormatter {..._} {...FlagEyeIconSet} />;
                }
                return FlagFormatter;
            case Metadata.FieldType.Date: return DateFormatter;
            case Metadata.FieldType.DateTime: return DateTimeFormatter;
            case Metadata.FieldType.Resource: return withNavigation ? ResourceWithNavigation : PersonWithoutNavigation;
            case Metadata.FieldType.User: return PersonWithoutNavigation;
            case Metadata.FieldType.Integer:
                if (settings) {
                    if (["Dropdown", "EnumSlider"].includes(settings.editControl)) {
                        return _ => <DropdownFormatter {..._} options={Metadata.getOptions(field)} />;
                    }
                    if (settings.editControl === "ToggleOption") {
                        return _ => <ToggleOptionFormatter {..._} options={Metadata.getOptions(field)} />;
                    }

                    return (props: { value?: number }) => <FormattedValue value={props.value} format={settings.format} entityType={entityType} fieldName={field.name} />;
                }

                break;
            case Metadata.FieldType.Decimal:
                if (settings) {
                    return (props: { value?: number }) => <FormattedValue value={props.value} format={settings.format} entityType={entityType} fieldName={field.name} />;
                }
                break;
            case Metadata.FieldType.Ref:
                return (props: { value?: IRefInfo }) => <RefFormatter data={props.value} withNavigation={withNavigation} />;
            case Metadata.FieldType.Portfolio:
                return (props: { value?: IPortfolioInfo }) => <PortfolioFormatter portfolio={props.value} withNavigation={withNavigation} />;
            case Metadata.FieldType.Program:
                return (props: { value?: IProgramInfo }) => <ProgramFormatter program={props.value} withNavigation={withNavigation} />;
            case Metadata.FieldType.Project:
                return (props: { value?: IProjectInfo }) => <ProjectFormatter project={props.value} withNavigation={withNavigation} />;
            case Metadata.FieldType.Challenge:
                return (props: { value?: IChallengeInfo }) => <span>{props.value?.name}</span>;
            case Metadata.FieldType.Idea:
                return (props: { value?: IIdeaInfo }) => <IdeaFormatter idea={props.value} withNavigation={withNavigation} />;
            case Metadata.FieldType.Objective:
                return (props: { value?: IObjectiveInfo }) => <span>{props.value?.name}</span>;
            case Metadata.FieldType.Layout:
                return (props: { value?: ILayoutInfo }) => <span>{props.value?.name}</span>;
            case Metadata.FieldType.ProcessStage:
                return (props: { value?: IProcessStageInfo }) => <CategoryColorStageView fieldLabel={getStageFieldLabel(entityType, field.label)} stageOption={props.value} />
            case Metadata.FieldType.Text:
                if (FieldsService.isTag(field)) {
                    return _ => <TagFormatter {..._} entityType={entityType} withNavigation={withNavigation} />;
                }

                if (FieldsService.isDropDown(field)) {
                    const options: Metadata.Option[] = settings?.options || [];
                    return _ => <TextDropdownFormatter {..._} options={options} />;
                }

                return (props) => <TextFormatter
                    {...props}
                    isMultiline={settings?.multiline}
                    isMultichoice={settings?.multichoice}
                    withNavigation={!settings?.isPlainText && withNavigation}
                />;
            case Metadata.FieldType.Hyperlink:
                return (props: { value?: Hyperlink }) => <HyperlinkFormatter hyperlink={props.value} />;
            case Metadata.FieldType.StagePhase:
                return (props: { value?: StagePhaseInfo }) => <TextFormatter value={props.value?.name} />
        }
        return undefined;
    }

    public static buildValidatorFromField(field: Metadata.Field) {
        if (field.isReadonly) {
            return Validator.new().build();
        }
        return DisplayFieldService.getValidatorBuilder(field).build();
    }

    public static buildValidator(field: Metadata.Field, ignoreRequiredSetting?: boolean) {
        return DisplayFieldService.getValidatorBuilder(field, ignoreRequiredSetting).build();
    }

    public static getValidatorBuilder(field: Metadata.Field, ignoreRequiredSetting?: boolean) {
        let validationBuilder: ValidationBuilder = Validator.new();
        if (Metadata.isRequired(field) && !ignoreRequiredSetting) {
            validationBuilder.required();
        }

        if (field.type == Metadata.FieldType.Decimal) {
            validationBuilder.decimal();
            if (field.settings?.minValue != undefined) {
                validationBuilder.min(field.settings?.minValue);
            }
            if (field.settings?.maxValue != undefined) {
                validationBuilder.max(field.settings?.maxValue);
            }
        }
        else if (field.type == Metadata.FieldType.Integer) {
            validationBuilder.int32();
            if (field.settings?.minValue != undefined) {
                validationBuilder.min(field.settings?.minValue);
            }
            if (field.settings?.maxValue != undefined) {
                validationBuilder.max(field.settings?.maxValue);
            }
        }
        else if (field.type == Metadata.FieldType.Date) {
            validationBuilder.date();
        }
        else if (field.type == Metadata.FieldType.DateTime) {
            validationBuilder.date();
        }
        else if ((field.type == Metadata.FieldType.User || field.type == Metadata.FieldType.Resource) && !(field.settings && field.settings.multichoice)) {
            validationBuilder.person();
        }

        if (field.settings?.editControl === "Slider") {
            validationBuilder.min(field.settings?.minValue).max(field.settings?.maxValue).step(field.settings?.minValue, field.settings?.step)
        }

        return validationBuilder;
    }

    public static buildCompactFieldInputElement(field: Metadata.Field, props: IInputProps, entityType?: EntityType, entity?: any, user?: UserState, tenant?: TenantState) {
        if (field.type === Metadata.FieldType.Integer && field.settings?.editControl === "EnumSlider") {
            return <DropdownInput {...props} inputProps={{ options: Metadata.getOptions(field), readOnly: field.isReadonly }} />;
        }
        if (field.type === Metadata.FieldType.Flag) {
            const yesValue = "Yes";
            const noValue = "No";
            const options: IDropdownOption[] = [{ key: noValue, text: "No" }, { key: yesValue, text: "Yes" }];
            return (
                <DropdownInput {...props}
                    onChanged={(fieldValue: string) => props.onChanged?.(fieldValue === yesValue)}
                    value={props.value ? yesValue : noValue}
                    inputProps={{ options, readOnly: field.isReadonly }} />
            );
        }

        return DisplayFieldService.buildFieldInputElement(field, props, entity, entityType, user, tenant);
    }

    public static buildFieldInputElement(field: Metadata.Field, props: IInputProps, entityType?: EntityType, entity?: any, user?: UserState, tenant?: TenantState, validator?: IValidator) {
        validator = props.readOnly || props.disabled ? undefined : validator ?? DisplayFieldService.buildValidator(field);
        switch (field.type) {
            case Metadata.FieldType.Text:
                return <TextFieldInputElement
                    {...props}
                    field={field}
                    validator={validator}
                    entityType={entityType}
                />;
            case Metadata.FieldType.Date:
            case Metadata.FieldType.DateTime:
                return <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={validator} />;
            case Metadata.FieldType.Decimal:
            case Metadata.FieldType.Integer:
                return <IntegerFieldInputElement
                    {...props}
                    field={field}
                    validator={validator}
                    entityType={entityType}
                    user={user}
                    tenant={tenant}
                />
            case Metadata.FieldType.Resource:
            case Metadata.FieldType.User:
                {
                    const multichoice = field.settings?.multichoice;
                    return <PersonPickerInput {...props}
                        searchUrl={`api/${searchPathMap[field.type]}/find`}
                        validator={validator}
                        multichoice={multichoice} />;
                }
            case Metadata.FieldType.Challenge:
            case Metadata.FieldType.Objective:
                return <EntityFieldInputElement
                    {...props}
                    field={field}
                    validator={validator}
                    entityType={entityType}
                />;
            case Metadata.FieldType.Idea: return <IdeaFieldInputElement
                {...props}
                field={field}
                validator={validator}
                entityType={entityType}
            />
            case Metadata.FieldType.Portfolio: return <PortfolioFieldInputElement
                {...props}
                field={field}
                validator={validator}
                entityType={entityType}
            />
            case Metadata.FieldType.Program: return <ProgramFieldInputElement
                {...props}
                field={field}
                validator={validator}
                entityType={entityType}
            />
            case Metadata.FieldType.Project: return <ProjectFieldInputElement
                {...props}
                field={field}
                validator={validator}
                entityType={entityType}
            />
            case Metadata.FieldType.Flag:
                return <FlagInput {...props} />;
            case Metadata.FieldType.Hyperlink:
                {
                    return <HyperlinkInput {...props} validator={validator} />
                }                
            case Metadata.FieldType.ProcessStage:
                {
                    return <div title={props.value?.name} className="color-status-dropdown-input process-stage">
                        <ColorDropdownOption data={{backgroundColor: props.value?.color}} text={props.value?.name} />
                    </div>
                }
            case Metadata.FieldType.StagePhase:
                {
                    return <TextInput value={props.value?.name} disabled />
                }
        }

        return <ErrorInput {...props} errorMessage={`Unable to match field type "${Metadata.FieldType[field.type as any] || field.type}"`} />;
    }

    public static buildFieldMultiSelectInput(field: Metadata.Field, value: any, onEditComplete: (data: any) => void,
        props?: { inputProps?: Dictionary<any>, autoExpand?: boolean }) {
        switch (field.type) {
            case Metadata.FieldType.Resource:
            case Metadata.FieldType.User:
                return <PersonPickerInput value={value} onEditComplete={onEditComplete}
                    searchUrl={`api/${searchPathMap[field.type]}/find`}
                    multichoice {...props} />;
            case Metadata.FieldType.Portfolio:
            case Metadata.FieldType.Program:
            case Metadata.FieldType.Project:
            case Metadata.FieldType.Challenge:
            case Metadata.FieldType.Idea:
            case Metadata.FieldType.Objective:
                {
                    const itemsProps: Partial<OptionsPickerProps> = {
                        onChange: (items) => onEditComplete(items?.map(_ => ({ id: _.key, name: _.text }))),
                        onResolveSuggestions: (filter: string, selectedItems?: Option[]) => {
                            const searchPath = `api/${searchPathMap[field.type]}/find`;
    
                            //todo: pass selected items to server in res mgmt branch
                            return post<EntityInfo[]>(searchPath, { name: filter })
                                .then(_ => _.filter(__ => !((selectedItems || []).some(___ => ___.key == __.id))))
                                .then(_ => _.map<Option>(__ => ({ key: __.id, text: __.name })));
                        },
                        selectedItems: (value as EntityInfo[] | undefined)?.map(_ => ({ key: _.id, text: _.name })) ?? [],
                        ...props
                    };
    
                    return <OptionsPicker {...itemsProps} />;
                }
            case Metadata.FieldType.Ref:
                return <EntityRefInput
                    value={value}
                    multichoice={true}
                    inputProps={{ ...field.settings, readOnly: field.isReadonly, ...props?.inputProps }}
                    onChanged={onEditComplete}
                    autoExpand={props?.autoExpand}
                />;
            case Metadata.FieldType.Text:
            case Metadata.FieldType.Integer:
            case Metadata.FieldType.Decimal:
                if (FieldsService.isDropDown(field) || FieldsService.isSlider(field, true)) {
                    const options: IDropdownOption[] = Metadata.getOptions(field);
                    const selecteditems: Option[] = value?.map((v: string | number) => options.find(_ => _.key == v)).filter(notUndefined) ?? [];

                    return <OptionsPicker
                        onChange={(opts?: Option[]) => onEditComplete(opts?.map(_ => _.key))}
                        onResolveSuggestions={(filter: string, selectedItems?: Option[]) => new Promise<Option[]>((r) => {
                            r(options.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && !selectedItems?.find(__ => __.key === _.key)));
                        })}
                        selectedItems={selecteditems}
                        {...props}
                    />;
                }
                if (field.type === Metadata.FieldType.Text && FieldsService.isTag(field)) {
                    return <TagPicker value={value} onEditComplete={onEditComplete} field={field} {...props} />;
                }
                if (field.type === Metadata.FieldType.Text) {
                    return <TextInput value={value} onEditComplete={onEditComplete} inputProps={props?.inputProps} />
                }
                if (field.type === Metadata.FieldType.Integer || field.type === Metadata.FieldType.Decimal) {
                    return FieldsService.isSlider(field, false)
                        ? <SliderFilter field={field} validator={DisplayFieldService.buildValidator(field, true)} value={value} onEditComplete={onEditComplete} />
                        : <NumberFilter field={field} validator={DisplayFieldService.buildValidator(field, true)} value={value} onEditComplete={onEditComplete} />;
                }
                return null;
            case Metadata.FieldType.Date:
            case Metadata.FieldType.DateTime:
                return <DateFilter field={field} validator={DisplayFieldService.buildValidator(field, true)} value={value}
                    disableAutoFocus={!props?.autoExpand} onEditComplete={onEditComplete} />
            case Metadata.FieldType.Flag:
                return <FlagFilter value={value} onEditComplete={onEditComplete} />
            case Metadata.FieldType.Group:
            case Metadata.FieldType.Hyperlink:
                return <TextInput value={value} onEditComplete={onEditComplete} inputProps={props?.inputProps} />
            default:
                return (null);
        }
    }

    public static buildGridEditorFieldInputElement(
        field: Metadata.Field,
        props: IInputProps,
        entityType?: EntityType,
        user?: UserState,
        tenant?: TenantState,
        validator?: IValidator
    ) {
        validator = props.readOnly || props.disabled ? undefined : validator ?? DisplayFieldService.buildValidator(field);

        switch (field.type) {
            case Metadata.FieldType.Text:
                return <TextFieldInputElement
                    {...props}
                    field={field}
                    validator={validator}
                    entityType={entityType}
                    inlineEditing
                />;
            case Metadata.FieldType.Date:
            case Metadata.FieldType.DateTime:
                return <DatePickerInput {...props}
                    inputProps={{ readOnly: field.isReadonly }}
                    validator={validator}
                />;
            case Metadata.FieldType.Decimal:
            case Metadata.FieldType.Integer:
                // Impact control
                if (field.settings?.editControl === "EnumSlider" && field.settings?.views?.list?.componentPath === "Impact") {
                    return <DropdownInput {...props} hideCaretDown
                        className={field.settings?.className}
                        value={props.value || field.defaultValue}
                        inputProps={{
                            options: Metadata.getOptions(field),
                            readOnly: field.isReadonly,
                            onRenderOption: (p: any) => <ImpactView value={p.key} />,
                            onRenderTitle: (p: any) => !p || !Array.isArray(p) ? <></> : <ImpactView value={p[0].key} />
                        }}
                    />
                }
                //task/Priority
                if (field.settings?.editControl === "EnumSlider"
                     && entityType === EntityType.Task && field.settings?.views?.list?.componentPath === "task/Priority") {
                    return <DropdownInput {...props} hideCaretDown
                        className={field.settings?.className}
                        value={props.value || field.defaultValue}
                        inputProps={{
                            options: Metadata.getOptions(field),
                            readOnly: field.isReadonly,
                            onRenderOption: (p: any) => <PriorityView value={p.key} />,
                            onRenderTitle: (p: any) => !p || !Array.isArray(p) ? <></> : <PriorityView value={p[0].key} />
                        }}
                    />
                }
                if (field.settings?.editControl === "EnumSlider") {
                    return <DropdownInput
                        {...props}
                        hideCaretDown
                        inputProps={{ options: Metadata.getOptions(field), readOnly: field.isReadonly }}
                    />
                }
                return <IntegerFieldInputElement
                    {...props}
                    field={field}
                    validator={validator}
                    entityType={entityType}
                    user={user}
                    tenant={tenant}
                    inlineEditing
                />;
            case Metadata.FieldType.Resource:
            case Metadata.FieldType.User:
                const multichoice = field.settings && field.settings.multichoice;
                return <PersonPickerInput {...props}
                    searchUrl={`api/${searchPathMap[field.type]}/find`}
                    validator={validator}
                    multichoice={multichoice}
                    onBlur={() => { }}
                    onEditComplete={() => { }}
                />;
            case Metadata.FieldType.Challenge:
            case Metadata.FieldType.Objective:
                return <EntityFieldInputElement
                    {...props}
                    field={field}
                    validator={validator}
                    entityType={entityType}
                />;
            case Metadata.FieldType.Idea:
                return <IdeaFieldInputElement
                    {...props}
                    field={field}
                    validator={validator}
                    entityType={entityType}
                    onEditComplete={() => { }}
                />;
            case Metadata.FieldType.Portfolio:
                return <PortfolioFieldInputElement
                    {...props}
                    field={field}
                    validator={validator}
                    entityType={entityType}
                    isConfigureMode
                    onEditComplete={() => { }}
                />;
            case Metadata.FieldType.Program:
                return <ProgramFieldInputElement
                    {...props}
                    field={field}
                    validator={validator}
                    entityType={entityType}
                    isConfigureMode
                    onEditComplete={() => { }}
                />;
            case Metadata.FieldType.Project:
                return <ProjectFieldInputElement
                    {...props}
                    field={field}
                    validator={validator}
                    entityType={entityType}
                    isConfigureMode
                    onEditComplete={() => { }}
                />;
            case Metadata.FieldType.Flag:
                return entityType === EntityType.KeyDate && field.name === "ShowOnTimeline"
                    ? <FlagEyeEditor {...props} readOnly={field.isReadonly} />
                    : <FlagEditor {...props} readOnly={field.isReadonly} />;
            case Metadata.FieldType.Hyperlink:
                return <HyperlinkInput {...props} validator={validator} onlyUrlEditor />;
            default:
                return <ErrorInput {...props} errorMessage={`Unable to match field type "${Metadata.FieldType[field.type as any] || field.type}"`} />;
        }
    }
}

export class FieldMappingService {
    public static validateMapping(
        fieldMappingKeyMap: IFieldMappingKeyMap[],
        externalProjectFields: ExternalFieldInfo[],
        ppmxfields: Metadata.Field[],
        externalFieldToPpmxFieldsMap: { [i: string]: MapConfig },
        mapping?: IFieldMapping[]): boolean {
        let isValid = true;
        fieldMappingKeyMap.forEach(_ => {
            if (_.mapping.isReadonly) {
                return;
            }

            if (!isValid ||
                !(FieldMappingService.validateRow(_.mapping, externalProjectFields, ppmxfields, externalFieldToPpmxFieldsMap).isValidRow)) {
                isValid = false;
                return false;
            }
            mapping && mapping.push({ ...(_.mapping as IFieldMapping) });
        });

        return isValid;
    }

    public static isSame(oldMapping: IFieldMapping[], newMappings: IFieldMappingKeyMap[]): boolean {
        if (oldMapping.length !== newMappings.length) {
            return false;
        }

        for (const newMapping of newMappings) {
            const map = newMapping.mapping;
            const oldMap = oldMapping.find(_ => _.externalField === map.externalField &&
                _.mappingType === map.mappingType &&
                _.transform === map.transform &&
                _.ppmxField === map.ppmxField);
            if (!oldMap) {
                return false;
            }
        }

        return true;
    }

    public static validateRow(
        row: IFieldMappingDto,
        externalProjectFields: ExternalFieldInfo[],
        ppmxfields: Metadata.Field[],
        externalFieldToPpmxFieldsMap: { [i: string]: MapConfig }
    ) {
        const externalField = externalProjectFields.find(_ => _.internalName === row.externalField);
        const ppmxField = ppmxfields.find(_ => _.name === row.ppmxField);

        let isValidRow = false;
        let errorMessage = "";
        if (!externalField || !ppmxField) {
            errorMessage = `Unable to save results until all fields are filled in`;
            return { isValidRow, errorMessage };
        }

        if (row.mappingType == null) {
            errorMessage = `Mapping type is not selected`;
            return { isValidRow, errorMessage }
        }

        if (ppmxField.isReadonly && row.mappingType === MappingType.ToPpmx) {
            errorMessage = `PPM Express field \"${Metadata.getLabel(ppmxField)}\" is readonly`;
            return { isValidRow, errorMessage }
        }

        if (externalField.isReadOnly && row.mappingType === MappingType.FromPpmx) {
            errorMessage = `Field \"${externalField.name}\" is readonly`;
            return { isValidRow, errorMessage }
        }

        if ((ppmxField.type === Metadata.FieldType.User || ppmxField.type === Metadata.FieldType.Resource) &&
            externalField.internalName === "ProjectOwnerId") {
            isValidRow = true;
            return { isValidRow, errorMessage }
        }

        if (ppmxField.type === Metadata.FieldType.Portfolio && externalField.type === "String" && row.mappingType === MappingType.FromPpmx) {
            isValidRow = true;
            return { isValidRow, errorMessage }
        }

        if (FieldsService.isTag(ppmxField) && externalField.type === "array") {
            isValidRow = true;
            return { isValidRow, errorMessage };
        }

        const ppmxFieldType = FieldsService.isDropDown(ppmxField) || FieldsService.isSlider(ppmxField, true) ? Metadata.FieldType.Text : ppmxField.type;
        const mapConfig = externalFieldToPpmxFieldsMap[externalField.type];
        if (mapConfig) {
            if (row.transform) {
                isValidRow = FieldMappingService.isTransformationPossible(ppmxField, externalField, externalFieldToPpmxFieldsMap);

                if (!isValidRow) {
                    errorMessage = `Transformation between field types \"${Metadata.fieldTypeMap[ppmxFieldType]}" and "${mapConfig ? mapConfig.label : "unknown"}" is not supported`;
                }
            } else {
                const rowValidationResult = FieldMappingService.validateRowMapping(mapConfig, ppmxFieldType, ppmxField);
                isValidRow = rowValidationResult.isValid;

                if (!isValidRow) {
                    if (rowValidationResult.message !== undefined) {
                        errorMessage = rowValidationResult.message;
                    } else if (FieldMappingService.isTransformationPossible(ppmxField, externalField, externalFieldToPpmxFieldsMap)) {
                        errorMessage = 'Field types are incompatible. They can be mapped only via transformation rules';
                    } else {
                        errorMessage = `Unable to match field types \"${Metadata.fieldTypeMap[ppmxFieldType]}" and "${mapConfig ? mapConfig.label : "unknown"}"`;

                    }
                }
            }
        } else {
            errorMessage = "Unable to match field types";
        }

        return { isValidRow, errorMessage }
    }

    public static isTransformationPossible(
        ppmxField: Metadata.Field,
        externalField: ExternalFieldInfo,
        externalFieldToPpmxFieldsMap: { [i: string]: MapConfig }): boolean {
        const externalTypesAvailableForTransformation = Object.keys(externalFieldToPpmxFieldsMap).reduce((ac, externalType) => {
            if (externalFieldToPpmxFieldsMap[externalType].types.find(_ => FieldMappingService.ppmxFieldTypesForTransformation.includes(_)) != null) {
                ac.push(externalType);
            }
            return ac;
        }, [] as string[]);

        return FieldMappingService.ppmxFieldTypesForTransformation.indexOf(ppmxField.type) !== -1 &&
            externalTypesAvailableForTransformation.indexOf(externalField.type) !== -1
    }

    private static ppmxFieldTypesForTransformation = [Metadata.FieldType.Text, Metadata.FieldType.Integer, Metadata.FieldType.Decimal, Metadata.FieldType.Flag];

    public static buildIdMap(mapping: IFieldMappingDto[] | null, defaultMapping: IFieldMappingDto): IFieldMappingKeyMap[] {
        return mapping && mapping.length > 0
            ? mapping.map(_ => ({ key: FieldMappingService.getNewKey(), mapping: _ }))
            : [{ key: FieldMappingService.getNewKey(), mapping: defaultMapping }];
    }

    public static getNewKey(): string {
        return getId();
    }

    public static getDefaultMapping(mappingTypes: MappingType[]): IFieldMappingDto {
        return { mappingType: mappingTypes[0] };
    }

    private static validateRowMapping(mapConfig: MapConfig, ppmxFieldType: Metadata.FieldType, ppmxField: Metadata.Field): { isValid: boolean, message?: string } {
        if (!mapConfig.types.includes(ppmxFieldType)) {
            return { isValid: false };
        }

        if (mapConfig.validateField) {
            return mapConfig.validateField(ppmxField, ppmxFieldType, mapConfig.label);
        }

        return { isValid: true };
    }
}

export class InputsService {
    public static buildOptionsPicker(props: Partial<OptionsPickerProps>) {
        return <OptionsPicker {...props} />;
    }
    public static buildSlider(props: SliderProps) {
        return <DebouncedSlider
            {...props}
            onDebouncedChange={(value: number) => { props.onEditComplete?.(value) }}
            showValue={true} />;
    }
}

type StateProps = {
    user: UserState;
    tenant: TenantState;
}
type OwnProps = {
    value: any,
    fieldName: string,
    format?: Metadata.FormatType,
    entityType?: EntityType
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

const FormattedValue = connect(mapStateToProps)((props: StateProps & OwnProps) => {
    return <span>{formatFieldValue(props.value, props.format, props.user, props.entityType, props.tenant, props.fieldName)}</span>
});
